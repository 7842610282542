<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button />
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Group Users</h1>
    </div>
    <div class="mt-4 cardStyle">
      <el-table row-key="id" class="group-table" header-row-class-name="thead-light" width="100%"
        :data="response.group.members" v-loading="loaders.group">
        <el-table-column label="Name" min-width="350">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.full_name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Email" min-width="350">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.email }}</div>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="180">
          <div slot-scope="{ $index, row }" class="d-flex" v-if="response.group && response.group.user_id != row.id ">
            <el-tooltip content="Remove group member" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/delete.svg" @click="promptRemove(row)" alt="">
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="modals">
      <remove-modal :loader="loaders.removeModal" :modal-text="removeModalText" :removeId="removeId"
        @onRemove="deleteRequest" />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui"
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "group-show",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    BackButton,
  },
  data() {
    return {
      loaders: {
        group: false,
      },
      removeModalText: '',
      removeId: '',
      response: {
        group: {},
      },
    }
  },
  mounted() {
    this.getGroup()
  },
  methods: {
    /**
     * Get Group By Id
     */
    getGroup() {
      let vm = this
      vm.loaders.group = true
      vm.$store.dispatch('GroupsModule/_show', vm.$route.params.id)
        .then(response => {
          vm.response.group = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Group',
            message: message
          })
        }).finally(() => {
          vm.loaders.group = false
        })
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this member from group.'
      this.removeId = row.id
    },

    /**
     * remove Group member
     * @param data
     */
    deleteRequest(id) {
      let vm = this
      vm.loaders.group = true
      vm.$store.dispatch('GroupsModule/_removeMember', { group_id: vm.response.group.id, member_id: id })
        .then(response => {
          vm.removeId = []
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: response.data.message
          })
          vm.loaders.group = false
          vm.getGroup();
        })
        .catch((error) => {
          vm.loaders.group = false
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Remove member',
            message: message
          })
        })
    },
  }
}
</script>

<style scoped></style>
